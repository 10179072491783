/* tslint:disable */
/* eslint-disable */
/**
 * Vocalise.API.Web
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EntitlementDTO
 */
export interface EntitlementDTO {
    /**
     * 
     * @type {string}
     * @memberof EntitlementDTO
     */
    id?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof EntitlementDTO
     */
    expireDate?: Date | null;
}

export function EntitlementDTOFromJSON(json: any): EntitlementDTO {
    return EntitlementDTOFromJSONTyped(json, false);
}

export function EntitlementDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): EntitlementDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'expireDate': !exists(json, 'expireDate') ? undefined : (json['expireDate'] === null ? null : new Date(json['expireDate'])),
    };
}

export function EntitlementDTOToJSON(value?: EntitlementDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'expireDate': value.expireDate === undefined ? undefined : (value.expireDate === null ? null : value.expireDate.toISOString()),
    };
}


