import { useAuth0 } from '@auth0/auth0-react'
import * as React from 'react'
import { useMemo } from 'react'
import { useQuery, queryCache } from 'react-query'
import { UserState } from 'api'
import { getAllWeek } from 'api/contentful-api'
import ProfileQuery from 'queries/profile-query'
import { ApplicationContextType } from 'types/context'

const ApplicationContext = React.createContext<ApplicationContextType>(
  (undefined as unknown) as ApplicationContextType
)

export const ApplicationProvider: React.FunctionComponent = ({ children }) => {
  const { profile } = ProfileQuery.useProfile()
  const [currentSubscription, setCurrentSubscription] = React.useState(false)
  const { isAuthenticated } = useAuth0()

  const { data: { maxWeek, activeWeek } = { maxWeek: 0, activeWeek: 0 } } = useQuery(
    ['weeks', profile?.activeWeek],
    async () =>
      getAllWeek().then((weeks) => {
        if (!profile) throw Error('No profile found')
        const uniqueWeekNumbers = weeks.reduce((acc, { week }) => {
          if (week && !acc.has(week)) acc.add(week)
          return acc
        }, new Set<number>())

        const weeksSinceSubscription = profile.activeWeek ?? 1
        const maxWeek =
          weeksSinceSubscription > uniqueWeekNumbers.size
            ? uniqueWeekNumbers.size
            : weeksSinceSubscription
        const activeWeek = weeksSinceSubscription % uniqueWeekNumbers.size || uniqueWeekNumbers.size
        return { maxWeek, activeWeek }
      }),
    {
      enabled: isAuthenticated && profile?.activeWeek !== undefined,
    }
  )

  React.useEffect(() => {
    if (!isAuthenticated || !profile) return
    if (profile.state === UserState.NUMBER_2) setCurrentSubscription(true)
  }, [isAuthenticated, profile])

  const setCurrentActiveWeek = React.useCallback(
    (week: number) => {
      console.log('ApplicationProvider setCurrentActiveWeek week', week)
      return queryCache.setQueryData<{ maxWeek: number; activeWeek: number }>(
        ['weeks', profile?.activeWeek],
        (prev) => ({ maxWeek: prev?.maxWeek ?? week, activeWeek: week })
      )
    },
    [profile]
  )

  const value = useMemo(
    () => ({
      activeWeek,
      maxWeek,
      setActiveWeek: setCurrentActiveWeek,
      subscriptionStatus: currentSubscription,
      setSubscriptionStatus: setCurrentSubscription,
    }),
    [activeWeek, maxWeek, currentSubscription, setCurrentActiveWeek]
  )

  return <ApplicationContext.Provider value={value}>{children}</ApplicationContext.Provider>
}

export const useApplicationContext = () => {
  const context = React.useContext(ApplicationContext)
  if (context === undefined) {
    throw new Error('useApplicationContext must be used within a ApplicationProvider')
  }
  return context
}
