/* tslint:disable */
/* eslint-disable */
/**
 * Vocalise.API.Web
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';

export interface ApiGroupcodeCreatePostRequest {
    customer: string;
    noUses: number;
}

export interface ApiGroupcodeRedeemPostRequest {
    code: string;
}

/**
 * 
 */
export class GroupCodeApi extends runtime.BaseAPI {

    /**
     */
    async apiGroupcodeCreatePostRaw(requestParameters: ApiGroupcodeCreatePostRequest): Promise<runtime.ApiResponse<Array<string>>> {
        if (requestParameters.customer === null || requestParameters.customer === undefined) {
            throw new runtime.RequiredError('customer','Required parameter requestParameters.customer was null or undefined when calling apiGroupcodeCreatePost.');
        }

        if (requestParameters.noUses === null || requestParameters.noUses === undefined) {
            throw new runtime.RequiredError('noUses','Required parameter requestParameters.noUses was null or undefined when calling apiGroupcodeCreatePost.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.customer !== undefined) {
            queryParameters['customer'] = requestParameters.customer;
        }

        if (requestParameters.noUses !== undefined) {
            queryParameters['noUses'] = requestParameters.noUses;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/groupcode/create`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async apiGroupcodeCreatePost(requestParameters: ApiGroupcodeCreatePostRequest): Promise<Array<string>> {
        const response = await this.apiGroupcodeCreatePostRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiGroupcodeRedeemPostRaw(requestParameters: ApiGroupcodeRedeemPostRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.code === null || requestParameters.code === undefined) {
            throw new runtime.RequiredError('code','Required parameter requestParameters.code was null or undefined when calling apiGroupcodeRedeemPost.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.code !== undefined) {
            queryParameters['code'] = requestParameters.code;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/groupcode/redeem`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiGroupcodeRedeemPost(requestParameters: ApiGroupcodeRedeemPostRequest): Promise<void> {
        await this.apiGroupcodeRedeemPostRaw(requestParameters);
    }

}
