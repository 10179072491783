import * as mixpanel from 'mixpanel-browser'
import * as React from 'react'
import ProfileQuery from 'queries/profile-query'

type ContextProps = {
  track: (...params: Parameters<typeof mixpanel.track>) => void
  reset: (...params: Parameters<typeof mixpanel.reset>) => void
}

export const MixpanelContext = React.createContext<ContextProps>(
  (undefined as unknown) as ContextProps
)

export const MixpanelProvider = ({ children }: React.PropsWithChildren<{}>) => {
  const hasInit = React.useRef(false)
  const { profile } = ProfileQuery.useProfile()

  const identifyUser = React.useCallback(() => {
    if (!hasInit.current) {
      console.warn('Mixpenel - Could not set identity, mixpenal not initialized')
      return
    }
    if (!profile?.id) {
      console.warn('Mixpenel - Could not set identity, no profile')
      return
    }

    mixpanel.identify(profile.id.toString())
    mixpanel.people.set({
      $name: profile.firstName,
      $email: profile.email,
    })
    console.log('Mixpenel - Successfully set identity', { profile })
  }, [profile])

  React.useEffect(() => {
    if (hasInit.current) return
    if (!window.global.REACT_APP_MIXPANEL_TOKEN) return

    console.log('Mixpenel - Initializing')
    mixpanel.init(window.global.REACT_APP_MIXPANEL_TOKEN, {
      debug: process.env.NODE_ENV === 'development',
      track_pageview: true,
      persistence: 'cookie',
      opt_out_tracking_by_default: false,
      ignore_dnt: true,
      loaded: (mixpanel) => {
        console.log('Mixpenel - Loaded successfully', { mixpanel })
        hasInit.current = true
        identifyUser()
      },
    })
  }, [identifyUser])

  React.useEffect(() => {
    identifyUser()
  }, [identifyUser])

  const track = React.useCallback((...params: Parameters<typeof mixpanel.track>) => {
    if (!hasInit.current) return
    mixpanel.track(...params)
    console.log('Mixpenel - Track', params)
  }, [])

  const reset = React.useCallback((...params: Parameters<typeof mixpanel.reset>) => {
    if (!hasInit.current) return
    mixpanel.reset(...params)
    console.log('Mixpanel - Reset', params)
  }, [])

  return <MixpanelContext.Provider value={{ track, reset }}>{children}</MixpanelContext.Provider>
}

export const useMixpanel = () => {
  const context = React.useContext(MixpanelContext)
  if (context === undefined) {
    throw new Error('useMixpanel must be used within a MixpanelProvider')
  }
  return context
}
