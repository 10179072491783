//eslint-disable-next-line
import jsonp from 'jsonp'

const baseUrl = window.global.REACT_APP_MAILCHIMP_BASE_URL

export const sendNewSubscriber = (email: string, name: string) => {
  const enc = encodeURIComponent
  const url = `${baseUrl}&EMAIL=${enc(email)}&FNAME=${enc(name)}`.replace('/post?', '/post-json?')
  jsonp(url, { param: 'c' }, (err, data) => {
    console.log(data)
  })
}
