import * as React from 'react'
import { Route, RouteProps } from 'react-router'
import useDocumentTitle from 'utilities/use-document-title'

interface IProps extends RouteProps {
  component: React.ComponentType
  title: string
}

const PublicRoute: React.FunctionComponent<IProps> = ({ component, title, children, ...rest }) => {
  useDocumentTitle(title)
  return <Route component={component} {...rest} />
}

export default PublicRoute
