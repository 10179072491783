import * as React from 'react'
import { useClearCacheCtx } from 'react-clear-cache'
import { Toaster } from 'react-hot-toast'
import { ReactQueryConfigProvider, ReactQueryConfig } from 'react-query'
import { ReactQueryDevtools } from 'react-query-devtools'
import { InstallMessage } from 'components/install-message'
import { Router } from 'components/router'
import { UpdateSnackbar } from 'components/update-snackbar'
import { ApplicationProvider } from 'contexts/application-context'
import { LocalStorageProvider } from 'contexts/local-storage-context'
import { LocalizationProvider } from 'localization'
import { GlobalStyle } from 'styles/global'

const queryConfig: ReactQueryConfig = {
  mutations: { useErrorBoundary: true },
  shared: { suspense: true },
  queries: {
    useErrorBoundary: true,
    staleTime: 60 * 1000, // Fetched data will be fresh for 1 minute befor becoming stale
    refetchOnWindowFocus: true,
    refetchOnMount: true,
  },
}

export function App() {
  const { isLatestVersion, emptyCacheStorage } = useClearCacheCtx()
  React.useEffect(() => {
    if (!isLatestVersion) emptyCacheStorage()
  }, [emptyCacheStorage, isLatestVersion])
  return (
    <LocalizationProvider>
      <GlobalStyle />
      <LocalStorageProvider>
        <ApplicationProvider>
          <Toaster />
          <ReactQueryConfigProvider config={queryConfig}>
            <Router />
            <InstallMessage />
            <UpdateSnackbar />
            <ReactQueryDevtools initialIsOpen={false} />
          </ReactQueryConfigProvider>
        </ApplicationProvider>
      </LocalStorageProvider>
    </LocalizationProvider>
  )
}
