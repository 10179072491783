/* tslint:disable */
/* eslint-disable */
/**
 * Vocalise.API.Web
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    FavoriteDTO,
    FavoriteDTOFromJSON,
    FavoriteDTOToJSON,
    FavoriteItem,
    FavoriteItemFromJSON,
    FavoriteItemToJSON,
} from '../models';

export interface ApiFavoriteDeleteRequest {
    contentfulId?: string;
}

export interface ApiFavoritePostRequest {
    favoriteItem?: FavoriteItem;
}

/**
 * 
 */
export class FavoriteApi extends runtime.BaseAPI {

    /**
     */
    async apiFavoriteDeleteRaw(requestParameters: ApiFavoriteDeleteRequest): Promise<runtime.ApiResponse<void>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.contentfulId !== undefined) {
            queryParameters['contentfulId'] = requestParameters.contentfulId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/favorite`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiFavoriteDelete(requestParameters: ApiFavoriteDeleteRequest): Promise<void> {
        await this.apiFavoriteDeleteRaw(requestParameters);
    }

    /**
     */
    async apiFavoriteGetRaw(): Promise<runtime.ApiResponse<Array<FavoriteDTO>>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/favorite`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FavoriteDTOFromJSON));
    }

    /**
     */
    async apiFavoriteGet(): Promise<Array<FavoriteDTO>> {
        const response = await this.apiFavoriteGetRaw();
        return await response.value();
    }

    /**
     */
    async apiFavoritePostRaw(requestParameters: ApiFavoritePostRequest): Promise<runtime.ApiResponse<void>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/favorite`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FavoriteItemToJSON(requestParameters.favoriteItem),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiFavoritePost(requestParameters: ApiFavoritePostRequest): Promise<void> {
        await this.apiFavoritePostRaw(requestParameters);
    }

}
