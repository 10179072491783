const ReactGA = require('react-ga')
const TagManager = require('react-gtm-module')

export const initGA = (trackingId: string) => {
  ReactGA.initialize(trackingId)
}

export const fireEventGA = (action: string, category: string, label: string, value: any) => {
  ReactGA.event({
    category,
    action,
    label,
    value,
  })
}

export const initTagManager = (gtmId: string) => {
  TagManager.initialize({
    gtmId: gtmId,
  })
}

export const pushUserid = (userId: number, page: string) => {
  const tagManagerArgs = {
    dataLayer: {
      user_id: userId,
      page,
    },
  }
  TagManager.dataLayer(tagManagerArgs)
}
