import { useAuth0 } from '@auth0/auth0-react'
import { Typography, Box, Link, Checkbox } from '@material-ui/core'
import * as React from 'react'
import { useForm } from 'react-hook-form'
import { css } from 'styled-components'
import { UserDTO } from 'api'
import Onboarding from 'assets/onboarding.png'
import ButtonFull from 'components/button-full'
import { Header } from 'components/header'
import { Page } from 'components/page'
import { OnboardingRoute, RedirectRoute } from 'config/routes'
import { useFormatMessage } from 'localization'
import { Colors } from 'styles/colors'
import { navigate } from 'utilities/react-router-hooks'
import { setLocalStorageForKey } from 'utilities/use-local-storage'

export const FIRST_NAME_KEY = 'firstName'

const CreateAccountPage: React.FC = () => {
  const f = useFormatMessage()
  const { loginWithRedirect } = useAuth0()
  const [checked, setChecked] = React.useState(false)
  const { register, handleSubmit, errors, watch } = useForm<UserDTO>()

  const handleCheckBox = (e: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(e.target.checked)
  }

  const submit = (d: UserDTO) => {
    setLocalStorageForKey(FIRST_NAME_KEY, d.firstName)
    loginWithRedirect({
      screen_hint: 'signup',
      redirectUri: `${window.location.origin}${RedirectRoute.generatePath()}`,
    })
  }

  return (
    <Page
      dense={true}
      bgcolor={Colors.White}
      css={css`
        display: flex;
        flex-direction: column;
        overflow: hidden;
      `}>
      <Header
        closeIconAction={() => {
          navigate(OnboardingRoute.generatePath())
        }}
        showCloseIcon={true}
        title={f('CREATE_YOUR_ACCOUNT')}
      />
      <Box
        css={css`
          display: flex;
          flex-direction: column;
          flex: 1;
          padding: 0 20px;
          overflow-y: auto;
          margin-bottom: 20px;
          justify-content: center;
        `}>
        <Box
          css={css`
            display: flex;
            justify-content: center;
            margin: 4vh;
          `}>
          <img
            alt={'logo'}
            css={css`
              max-width: 28vh;
              margin-bottom: 20px;
              align-self: center;
            `}
            src={Onboarding}
          />
        </Box>
        <Typography align="center" variant="body1">
          {f('CREATE_ACCOUNT_DESCRIPTION')}
        </Typography>
        <Box
          css={css`
            display: flex;
            align-items: center;
            justify-content: center;
          `}>
          <Checkbox
            checked={checked}
            onChange={handleCheckBox}
            css={css`
              color: ${Colors.Silver};
            `}
          />
          <Typography align="center" variant="body1">
            {`${f('I_ACCEPT')} `}
            <Link
              target="_blank"
              href="https://vocalise.app/privacy-policy"
              color="inherit"
              css={css`
                font-weight: normal;
                text-decoration: underline;
              `}>
              {`${f('PRIVACY_POLICY').toLocaleLowerCase()}`}
            </Link>
          </Typography>
        </Box>
        <form
          onSubmit={handleSubmit((d) => {
            if (!checked && d.firstName === '') return
            submit(d)
          })}>
          <Typography
            css={css`
              margin: 3.3vh 0px 12px 0px;
              font-weight: 700;
              font-style: 'Open Sans';
              font-size: 0.875rem;
              color: ${Colors.Black};
            `}>
            {f('FIRST_NAME')}
          </Typography>
          <input
            css={css`
              width: 100%;
              height: 48px;
              border: none;
              border-radius: 16px;
              background: ${Colors.HintOfRed};
              padding: 13px 16px;
              font-family: 'Open Sans';
              font-size: 1rem;
              font-weight: 400;
            `}
            name="firstName"
            ref={register({ required: true })}
            placeholder={f('WRITE_YOUR_FIRST_NAME')}
          />
          {errors?.firstName?.message && <span>{errors.firstName.message}</span>}
        </form>
      </Box>

      <Box
        css={css`
          padding: 0px 20px;
          margin-bottom: 5%;
        `}>
        <ButtonFull
          id="create-account-button"
          enable={checked && watch('firstName') !== ''}
          title={f('CONTINUE')}
          buttonColor={Colors.Amethyst}
          titleColor={Colors.WhiteSmoke}
          height={'48px'}
          clickAction={handleSubmit((d) => submit(d))}
        />
      </Box>
    </Page>
  )
}

export default CreateAccountPage
