import { createClient } from 'contentful-management'
import { LocaleProps } from 'contentful-management/dist/typings/entities/locale'

var ContentfulManagementClient = createClient({
  accessToken: window.global.REACT_APP_CONTENTFUL_MANAGEMENT_TOKEN!,
})

export async function getLocaleCollection() {
  const localeCollection = await ContentfulManagementClient.getSpace(
    window.global.REACT_APP_CONTENTFUL_SPACE!
  )
    .then((space) => {
      const environment = space.getEnvironment(window.global.REACT_APP_CONTENTFUL_ENVIRONMENT!)
      return environment
    })
    .then((environtment) => environtment.getLocales())
    .then((entries) => {
      return entries.items.map((entry: LocaleProps) => entry)
    })
  return localeCollection
}
