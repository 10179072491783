import { Auth0Provider } from '@auth0/auth0-react'
import * as React from 'react'
import { ClearCacheProvider } from 'react-clear-cache'
import * as ReactDOM from 'react-dom'
import ErrorBoundary from 'components/error-boundary'
import { Spinner } from 'components/spinner'
import { ThemeProvider } from 'components/theme-provider'
import ServiceWorkerContext from 'contexts/service-worker-context'
import 'styles/index.css'
import { THEME } from 'styles/theme'
import { initGA } from 'utilities/tracking'
import { App } from './App'

//initialize react GA (Google Analytic)
if (window.global.REACT_APP_GOOGLE_ANALYTIC_TRACKING_ID !== undefined) {
  initGA(window.global.REACT_APP_GOOGLE_ANALYTIC_TRACKING_ID)
}

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary>
      <ClearCacheProvider duration={5000}>
        <ServiceWorkerContext.Provider>
          <ThemeProvider theme={THEME}>
            <React.Suspense fallback={<Spinner />}>
              <Auth0Provider
                domain={window.global.REACT_APP_AUTH0_DOMAIN!}
                clientId={window.global.REACT_APP_AUTH0_CLIENTID!}
                redirectUri={window.global.REACT_APP_BASE_URL!}
                // onRedirectCallback={(appState) =>
                //   navigate(appState.returnTo ?? window.location.href)
                // }
                audience={window.global.REACT_APP_AUTH0_AUDIENCE}
                cacheLocation="localstorage"
                scope="openid profile email">
                <App />
              </Auth0Provider>
            </React.Suspense>
          </ThemeProvider>
        </ServiceWorkerContext.Provider>
      </ClearCacheProvider>
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root')
)
