/* tslint:disable */
/* eslint-disable */
/**
 * Vocalise.API.Web
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ProductModel
 */
export interface ProductModel {
    /**
     * 
     * @type {string}
     * @memberof ProductModel
     */
    productID?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductModel
     */
    productName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductModel
     */
    interval?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductModel
     */
    currency?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProductModel
     */
    price?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductModel
     */
    priceID?: string | null;
}

export function ProductModelFromJSON(json: any): ProductModel {
    return ProductModelFromJSONTyped(json, false);
}

export function ProductModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProductModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'productID': !exists(json, 'productID') ? undefined : json['productID'],
        'productName': !exists(json, 'productName') ? undefined : json['productName'],
        'interval': !exists(json, 'interval') ? undefined : json['interval'],
        'currency': !exists(json, 'currency') ? undefined : json['currency'],
        'price': !exists(json, 'price') ? undefined : json['price'],
        'priceID': !exists(json, 'priceID') ? undefined : json['priceID'],
    };
}

export function ProductModelToJSON(value?: ProductModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'productID': value.productID,
        'productName': value.productName,
        'interval': value.interval,
        'currency': value.currency,
        'price': value.price,
        'priceID': value.priceID,
    };
}


