import { useQuery, queryCache } from 'react-query'
import { FavoriteDTO } from 'api'
import {
  getFeaturedSessions,
  getFreeSessions,
  getProgram,
  getSessions,
  getSubjects,
  getSubjectsAndWeek,
  getWeek,
  getLibraryCategories,
  getSessionsIn,
  getDailyTip,
} from 'api/contentful-api'
import { getLocaleCollection } from 'api/contentful-management-api'

const PREFIX = 'contentful'

export function useProgramQuery(programId: string) {
  return useQuery([PREFIX, 'program', programId], (_, program) => getProgram(programId), {
    enabled: programId !== undefined,
  })
}

export function useWeekQuery(weekNumber: number) {
  return useQuery([PREFIX, 'week', weekNumber], (_, week) => getWeek(weekNumber), {
    enabled: weekNumber !== undefined,
  })
}

export function useSubjectsQuery() {
  return useQuery([PREFIX, 'subjects'], () => getSubjects())
}

export function useSessionsQuery(sessionId: string) {
  return useQuery([PREFIX, 'sessions', sessionId], () => getSessions(sessionId), {
    enabled: sessionId !== undefined,
  })
}

export function forceRefetchSession() {
  queryCache.removeQueries([PREFIX, 'sessions'])
}

export function forceRefetchSubject() {
  queryCache.removeQueries([PREFIX, 'subjects'])
}

export function forceRefetchLibrary() {
  queryCache.removeQueries([PREFIX, 'libraryCategories'])
}

export function useFeaturedSessionsQuery() {
  return useQuery([PREFIX, 'sessionsFeatured'], () => getFeaturedSessions())
}

export function useFreeSessionsQuery() {
  return useQuery([PREFIX, 'sessionsFree'], () => getFreeSessions())
}

export function useSubjectsAndWeekQuery() {
  return useQuery([PREFIX, 'subjectsAndWeeks'], () => getSubjectsAndWeek())
}

export function useLocaleCollection() {
  return useQuery([PREFIX, 'localeCollection'], () => getLocaleCollection())
}

export function invalidateContentfulQueries() {
  queryCache.invalidateQueries([PREFIX], { refetchInactive: true })
}

export function useLibraryCategoriesQuery() {
  return useQuery([PREFIX, 'libraryCategories'], () => getLibraryCategories())
}

export function useDailyTip(day: number) {
  return useQuery([PREFIX, 'dailyTip'], () => getDailyTip(day))
}

export function useSessionsInQuery(favorites: FavoriteDTO[]) {
  const sessionIdList = favorites.map((fav) => fav.contentfulId)
  const sessionString = sessionIdList.toString()

  return useQuery([PREFIX, 'sessions', sessionString], () => getSessionsIn(sessionString), {
    enabled: sessionString !== undefined,
  })
}

export async function sessionsInQuery(favorites: FavoriteDTO[]) {
  const sessionIdList = favorites.map((fav) => fav.contentfulId)
  const sessionString = sessionIdList.toString()
  return await getSessionsIn(sessionString)
}
