import { ButtonBase } from '@material-ui/core'
import * as React from 'react'
import { css } from 'styled-components'
import { Colors } from 'styles/colors'

interface IButtonFullProps {
  id?: string
  title: string
  clickAction: () => void
  titleColor?: string
  buttonColor?: string
  width?: string
  height?: string
  titleSize?: string
  titleWeight?: string
  enable?: boolean
}

const ButtonFullView: React.FC<IButtonFullProps> = ({
  id,
  title,
  clickAction,
  titleColor,
  buttonColor = Colors.Gray,
  width = '100%',
  height = '50px',
  titleSize = '1rem',
  enable = true,
  titleWeight = '700',
}) => {
  return (
    <ButtonBase
      id={id}
      disabled={!enable}
      css={css`
        background: ${buttonColor};
        width: ${width};
        height: ${height};
        border-radius: 16px;
        color: ${titleColor || Colors.Black};
        font-size: ${titleSize};
        font-weight: ${titleWeight};
        opacity: ${enable ? '1' : '0.5'};
      `}
      onClick={clickAction}>
      {title}
    </ButtonBase>
  )
}

export default ButtonFullView
