import { Configuration } from 'api'
import { httpMiddleware } from 'utilities/http-middleware'

const middleware = [httpMiddleware]

function randomDelay<T>(value: T) {
  if (process.env.NODE_ENV !== 'development') return Promise.resolve(value)
  const delay = Math.random() * 700 + 300
  return new Promise<T>((resolve) => setTimeout(() => resolve(value), delay))
}

export const configuration = new Configuration({
  fetchApi: (input: RequestInfo, init?: RequestInit) => window.fetch(input, init).then(randomDelay),
  middleware,
  basePath: window.global.REACT_APP_API_URL,
})
