import { Notifier } from '@airbrake/browser'
import * as React from 'react'

interface IProps {
  children: React.ReactNode
  fallback?: React.ReactNode
}

interface IState {
  hasError: boolean
  airbrake?: Notifier
}

class ErrorBoundary extends React.Component<IProps, IState> {
  public state: IState = {
    hasError: false,
    airbrake:
      window.global.REACT_APP_AIR_BRAKE_PROJECT_ID && window.global.REACT_APP_AIR_BRAKE_PROJECT_KEY
        ? new Notifier({
            projectId: parseInt(window.global.REACT_APP_AIR_BRAKE_PROJECT_ID),
            projectKey: window.global.REACT_APP_AIR_BRAKE_PROJECT_KEY,
          })
        : undefined,
  }

  public static getDerivedStateFromError(_: Error): IState {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  public componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    this.state.airbrake?.notify({
      error: error,
      params: { info: errorInfo },
    })
  }

  public render() {
    if (this.state.hasError) {
      return this.props.fallback ?? <h1>Sorry.. there was an error</h1>
    }

    return this.props.children
  }
}

export default ErrorBoundary
