/* tslint:disable */
/* eslint-disable */
/**
 * Vocalise.API.Web
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    PaymentSessionDTO,
    PaymentSessionDTOFromJSON,
    PaymentSessionDTOToJSON,
    PriceCurrencyDTO,
    PriceCurrencyDTOFromJSON,
    PriceCurrencyDTOToJSON,
    ProductModel,
    ProductModelFromJSON,
    ProductModelToJSON,
} from '../models';

export interface ApiStripeCheckoutPostRequest {
    countryCode: string;
    priceId: string;
    successUrl: string;
    cancelUrl: string;
}

export interface ApiStripeProductsGetRequest {
    countryCode: string;
}

/**
 * 
 */
export class StripeApi extends runtime.BaseAPI {

    /**
     */
    async apiStripeAvailablepricesGetRaw(): Promise<runtime.ApiResponse<Array<PriceCurrencyDTO>>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/stripe/availableprices`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PriceCurrencyDTOFromJSON));
    }

    /**
     */
    async apiStripeAvailablepricesGet(): Promise<Array<PriceCurrencyDTO>> {
        const response = await this.apiStripeAvailablepricesGetRaw();
        return await response.value();
    }

    /**
     */
    async apiStripeCheckoutPostRaw(requestParameters: ApiStripeCheckoutPostRequest): Promise<runtime.ApiResponse<PaymentSessionDTO>> {
        if (requestParameters.countryCode === null || requestParameters.countryCode === undefined) {
            throw new runtime.RequiredError('countryCode','Required parameter requestParameters.countryCode was null or undefined when calling apiStripeCheckoutPost.');
        }

        if (requestParameters.priceId === null || requestParameters.priceId === undefined) {
            throw new runtime.RequiredError('priceId','Required parameter requestParameters.priceId was null or undefined when calling apiStripeCheckoutPost.');
        }

        if (requestParameters.successUrl === null || requestParameters.successUrl === undefined) {
            throw new runtime.RequiredError('successUrl','Required parameter requestParameters.successUrl was null or undefined when calling apiStripeCheckoutPost.');
        }

        if (requestParameters.cancelUrl === null || requestParameters.cancelUrl === undefined) {
            throw new runtime.RequiredError('cancelUrl','Required parameter requestParameters.cancelUrl was null or undefined when calling apiStripeCheckoutPost.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.countryCode !== undefined) {
            queryParameters['countryCode'] = requestParameters.countryCode;
        }

        if (requestParameters.priceId !== undefined) {
            queryParameters['priceId'] = requestParameters.priceId;
        }

        if (requestParameters.successUrl !== undefined) {
            queryParameters['successUrl'] = requestParameters.successUrl;
        }

        if (requestParameters.cancelUrl !== undefined) {
            queryParameters['cancelUrl'] = requestParameters.cancelUrl;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/stripe/checkout`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PaymentSessionDTOFromJSON(jsonValue));
    }

    /**
     */
    async apiStripeCheckoutPost(requestParameters: ApiStripeCheckoutPostRequest): Promise<PaymentSessionDTO> {
        const response = await this.apiStripeCheckoutPostRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiStripePortalsessionPostRaw(): Promise<runtime.ApiResponse<string>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/stripe/portalsession`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async apiStripePortalsessionPost(): Promise<string> {
        const response = await this.apiStripePortalsessionPostRaw();
        return await response.value();
    }

    /**
     */
    async apiStripeProductsGetRaw(requestParameters: ApiStripeProductsGetRequest): Promise<runtime.ApiResponse<Array<ProductModel>>> {
        if (requestParameters.countryCode === null || requestParameters.countryCode === undefined) {
            throw new runtime.RequiredError('countryCode','Required parameter requestParameters.countryCode was null or undefined when calling apiStripeProductsGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.countryCode !== undefined) {
            queryParameters['countryCode'] = requestParameters.countryCode;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/stripe/products`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ProductModelFromJSON));
    }

    /**
     */
    async apiStripeProductsGet(requestParameters: ApiStripeProductsGetRequest): Promise<Array<ProductModel>> {
        const response = await this.apiStripeProductsGetRaw(requestParameters);
        return await response.value();
    }

}
