import { Box, Typography, ButtonBase } from '@material-ui/core'
import * as React from 'react'
import { css } from 'styled-components'

type HeaderViewProps = {
  title: string
  showMenuIcon?: boolean
  menuIconPosition?: 'left' | 'right'
  showCloseIcon?: boolean
  closeIconAction?: () => void
  menuIconAction?: () => void
}

const HeaderView: React.FC<HeaderViewProps> = ({
  title,
  showMenuIcon,
  menuIconPosition,
  showCloseIcon,
  closeIconAction,
  menuIconAction,
}) => {
  const [displayIcon, setDisplayIcon] = React.useState(false)
  const [menuPosition, setMenuPosition] = React.useState('right')
  const [closeIcon, setCloseIcon] = React.useState(false)

  React.useEffect(() => {
    if (showMenuIcon) setDisplayIcon(showMenuIcon)
    if (menuIconPosition) setMenuPosition(menuIconPosition)
    if (showCloseIcon) setCloseIcon(showCloseIcon)
  }, [showMenuIcon, menuIconPosition, showCloseIcon])

  return (
    <Box
      css={css`
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px;
      `}>
      {menuPosition === 'left' && (
        <ButtonBase onClick={menuIconAction ? menuIconAction : () => {}}>
          <svg
            css={css`
              display: ${displayIcon ? 'block' : 'none'};
            `}
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M13 18H20M4 6H20H4ZM4 12H20H4Z"
              stroke="#3F3F46"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </ButtonBase>
      )}

      <Typography
        css={css`
          font-size: 0.9rem;
          font-family: 'Open Sans';
          font-weight: 700;
          font-style: normal;
          margin-left: ${menuPosition === 'left' ? '15px' : '0px'};
          flex: 1;
        `}>
        {title}
      </Typography>
      {menuPosition === 'right' && (
        <ButtonBase onClick={menuIconAction ? menuIconAction : () => {}}>
          <svg
            css={css`
              display: ${displayIcon ? 'block' : 'none'};
            `}
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M13 18H20M4 6H20H4ZM4 12H20H4Z"
              stroke="#3F3F46"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </ButtonBase>
      )}
      {closeIcon && (
        <ButtonBase
          onClick={() => {
            if (closeIconAction) {
              closeIconAction()
            }
          }}
          css={css`
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
          `}>
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M6 6L18 18M6 18L18 6L6 18Z"
              stroke="#3F3F46"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </ButtonBase>
      )}
    </Box>
  )
}

export default HeaderView
