/* tslint:disable */
/* eslint-disable */
/**
 * Vocalise.API.Web
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ProgressDTO
 */
export interface ProgressDTO {
    /**
     * 
     * @type {number}
     * @memberof ProgressDTO
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof ProgressDTO
     */
    contentfulProgramId: string;
    /**
     * 
     * @type {string}
     * @memberof ProgressDTO
     */
    contentfulSubjectId: string;
    /**
     * 
     * @type {string}
     * @memberof ProgressDTO
     */
    contentfulWeekId: string;
    /**
     * 
     * @type {string}
     * @memberof ProgressDTO
     */
    contentfulSessionId: string;
    /**
     * 
     * @type {number}
     * @memberof ProgressDTO
     */
    userId: number;
}

export function ProgressDTOFromJSON(json: any): ProgressDTO {
    return ProgressDTOFromJSONTyped(json, false);
}

export function ProgressDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProgressDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'contentfulProgramId': json['contentfulProgramId'],
        'contentfulSubjectId': json['contentfulSubjectId'],
        'contentfulWeekId': json['contentfulWeekId'],
        'contentfulSessionId': json['contentfulSessionId'],
        'userId': json['userId'],
    };
}

export function ProgressDTOToJSON(value?: ProgressDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'contentfulProgramId': value.contentfulProgramId,
        'contentfulSubjectId': value.contentfulSubjectId,
        'contentfulWeekId': value.contentfulWeekId,
        'contentfulSessionId': value.contentfulSessionId,
        'userId': value.userId,
    };
}


