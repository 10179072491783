/* tslint:disable */
/* eslint-disable */
/**
 * Vocalise.API.Web
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    UserDTO,
    UserDTOFromJSON,
    UserDTOToJSON,
    UserItem,
    UserItemFromJSON,
    UserItemToJSON,
} from '../models';

export interface ApiUserGetRequest {
    forceRefresh?: boolean;
}

export interface ApiUserPostRequest {
    userItem?: UserItem;
}

export interface ApiUserPutRequest {
    userItem?: UserItem;
}

/**
 * 
 */
export class UserApi extends runtime.BaseAPI {

    /**
     */
    async apiUserGetRaw(requestParameters: ApiUserGetRequest): Promise<runtime.ApiResponse<UserDTO>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.forceRefresh !== undefined) {
            queryParameters['forceRefresh'] = requestParameters.forceRefresh;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/user`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UserDTOFromJSON(jsonValue));
    }

    /**
     */
    async apiUserGet(requestParameters: ApiUserGetRequest): Promise<UserDTO> {
        const response = await this.apiUserGetRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiUserPostRaw(requestParameters: ApiUserPostRequest): Promise<runtime.ApiResponse<UserDTO>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/user`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserItemToJSON(requestParameters.userItem),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UserDTOFromJSON(jsonValue));
    }

    /**
     */
    async apiUserPost(requestParameters: ApiUserPostRequest): Promise<UserDTO> {
        const response = await this.apiUserPostRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiUserPutRaw(requestParameters: ApiUserPutRequest): Promise<runtime.ApiResponse<void>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/user`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UserItemToJSON(requestParameters.userItem),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiUserPut(requestParameters: ApiUserPutRequest): Promise<void> {
        await this.apiUserPutRaw(requestParameters);
    }

    /**
     */
    async apiUserRemoveDeleteRaw(): Promise<runtime.ApiResponse<void>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/user/remove`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiUserRemoveDelete(): Promise<void> {
        await this.apiUserRemoveDeleteRaw();
    }

}
