/* tslint:disable */
/* eslint-disable */
/**
 * Vocalise.API.Web
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PaymentSessionDTO
 */
export interface PaymentSessionDTO {
    /**
     * 
     * @type {string}
     * @memberof PaymentSessionDTO
     */
    sessionID?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PaymentSessionDTO
     */
    taxRateID?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PaymentSessionDTO
     */
    isoCountryCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PaymentSessionDTO
     */
    priceId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PaymentSessionDTO
     */
    redirectUrl?: string | null;
}

export function PaymentSessionDTOFromJSON(json: any): PaymentSessionDTO {
    return PaymentSessionDTOFromJSONTyped(json, false);
}

export function PaymentSessionDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaymentSessionDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sessionID': !exists(json, 'sessionID') ? undefined : json['sessionID'],
        'taxRateID': !exists(json, 'taxRateID') ? undefined : json['taxRateID'],
        'isoCountryCode': !exists(json, 'isoCountryCode') ? undefined : json['isoCountryCode'],
        'priceId': !exists(json, 'priceId') ? undefined : json['priceId'],
        'redirectUrl': !exists(json, 'redirectUrl') ? undefined : json['redirectUrl'],
    };
}

export function PaymentSessionDTOToJSON(value?: PaymentSessionDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sessionID': value.sessionID,
        'taxRateID': value.taxRateID,
        'isoCountryCode': value.isoCountryCode,
        'priceId': value.priceId,
        'redirectUrl': value.redirectUrl,
    };
}


