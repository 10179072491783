/* tslint:disable */
/* eslint-disable */
/**
 * Vocalise.API.Web
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum UserState {
    NUMBER_0 = 0,
    NUMBER_1 = 1,
    NUMBER_2 = 2
}

export function UserStateFromJSON(json: any): UserState {
    return UserStateFromJSONTyped(json, false);
}

export function UserStateFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserState {
    return json as UserState;
}

export function UserStateToJSON(value?: UserState | null): any {
    return value as any;
}

