import { Middleware, FetchParams } from 'api'

const spaTokenLoc =
  '@@auth0spajs@@::' +
  window.global.REACT_APP_AUTH0_CLIENTID! +
  '::' +
  window.global.REACT_APP_AUTH0_AUDIENCE! +
  '::openid profile email'

const abortController = new AbortController()
export const httpMiddleware: Middleware = {
  pre: ({ init: initOptions, url }) => {
    const tokenString = window.localStorage.getItem(spaTokenLoc)
    const accessToken = tokenString ? (JSON.parse(tokenString).body.access_token as string) : null
    const authorizationHeader = accessToken ? { Authorization: `Bearer ${accessToken}` } : null

    const init: RequestInit = {
      signal: abortController.signal,
      ...initOptions,
      headers: {
        'Access-Control-Allow-Origin': 'https://localhost:3000',
        'Content-Type': 'application/json',
        Accept: 'application/json',
        ...(authorizationHeader ? authorizationHeader : {}),
        ...initOptions.headers,
      },
    }

    // Add cancel property to promise object for react-query to cancel request if aborted
    const promise: Promise<FetchParams> & {
      cancel?: typeof abortController.abort
    } = Promise.resolve({
      url,
      init: { ...initOptions, ...init },
    })

    promise.cancel = abortController.abort
    return promise
  },
  post: async ({ response }) => {
    if (response.ok) return Promise.resolve(response)
    const isJson = response.headers.get('content-type')?.includes('application/json')
    const data = isJson ? await response.json() : null

    if (!isJson) return Promise.reject(response)

    // Add properties for different status codes
    const statusContainer = {
      ...(response.status === 400 && { errors: convertKeys(toLowerCamelCase)(data.errors) }), // Bad Request (Typically form error)
      ...(response.status === 409 && { status: data.detail }), // Conflict
    }

    // If no keys were added, reject json, else reject status container
    if (Object.keys(statusContainer).length > 0) return Promise.reject(statusContainer)
    return data
  },
}

const convertKeys = (func: (key: string) => string) => (object: Object): Object => {
  return Object.keys(object).reduce((prev, key) => ({ ...prev, [func(key)]: object[key] }), {})
}

function toLowerCamelCase(str: string): string {
  if (str.length === 0) {
    return ''
  }
  return str[0].toLowerCase() + str.slice(1, str.length)
}
