/**
 * This file contains all american english translations (messages) used in the web app.
 * Must be a flat key-value object where keys are sorted in alphanumerical order.
 * We are using the ICU message syntax: https://formatjs.io/guides/message-syntax/
 * Format keys using upper snake case and name them by a hierarchy representing location inside the app
 * Example: 'BASIC_BUTTON_ADD_TEXT'
 */

export default {
  HELLO: 'Hello',
  SERVICE_WORKER_PROVIDER_RELOAD_BUTTON: 'Reload',
  SERVICE_WORKER_PROVIDER_NEW_VERSION: 'New versions released!',
  TEST_USE_FORMAT_MESSAGE_TEXT: 'Increment',
  TEST_USE_FORMAT_MESSAGE_TEXT_AND_VALUE: 'Value {value}',
  DASHBOARD_PAGE_VIEW_GREETING_MORNING: 'Good morning',
  DASHBOARD_PAGE_VIEW_GREETING_DAY: 'Good day',
  DASHBOARD_PAGE_VIEW_GREETING_EVENING: 'Good evening',
  ON_BOARDING_TITLE_1: 'We are so happy you are here!',
  ON_BOARDING_DESCRIPTION_1:
    'Start practicing singing at home combining classical voice coaching with physical training for singers.',
  ON_BOARDING_TITLE_2: 'Weekly practice',
  ON_BOARDING_DESCRIPTION_2:
    'Your weekly program consist of accompanied vocal exercises and training to improve your breathing, focus and posture to support a free and healthy singing voice.',
  ON_BOARDING_TITLE_3: 'Add to your home screen',
  ON_BOARDING_DESCRIPTION_3:
    "Don't forget to add this app to the home screen for your best experience.",
  LOGIN: 'Login',
  LOGOUT: 'Sign out',
  SETTINGS: 'Settings',
  RECOMENDED_PRACTICE: 'Recommended Exercises',
  LANGUAGE: 'Language',
  LANGUAGE_ERROR: 'Could not load languages',
  CONTINUE: 'Continue',
  CHECKOUT: 'Checkout',
  CREATE_PROFILE: 'Create Profile',
  EMAIL: 'Email',
  INVALID_EMAIL_ADDRESS: 'Invalid Email Address',
  FIRST_NAME: 'First name',
  BY_CLICKING_CONTINUE_YOU_AGREE_TO_VOCALISE: 'By clicking continue you agree to the Vocalise',
  TERM_OF_SERVICE: 'Terms of Service',
  PRIVACY_POLICY: 'Privacy policy',
  AND: 'And',
  COUNTRY: 'Country',
  OTHER: 'Other',
  PRICE: 'Price',
  CONTACT_VOCALISE: 'Contact {value} for support',
  PAY: 'Pay',
  YOUR_WEEKLY_TRAINING: 'Your Weekly Training',
  WEEK: 'Week',
  TRY_FREE_EXCERCISES: 'Try our free exercises',
  WHERE_ARE_YOU: 'Where are you?',
  PAYMENT_TITLE: 'Try Vocalise free trial 14 days',
  PAYMENT_DESCRIPTION:
    'Improve your singing in a healthy way. There after you can discontinue your subscription at any time.',
  PAYMENT_PLAN_TITLE: 'Choose payment plan',
  PAYMENT_PROMOTION: '*Use Promotion code EARLYBIRD at checkout for 30% earlybird discount',
  START_NOW: 'Start now',
  THANK_YOU: 'Thank you',
  PAYMENT_SUCCESS_DESCRIPTION: 'Thank you for buying Vocalise. Enjoy your singing practice',
  USE: 'Use',
  PAYMENT_CANCEL_TITLE: 'Are you having problems with the payment?',
  PAYMENT_CANCEL_DESCRIPTION:
    'Please contact us at info@vocalise.app with any feedback or questions',
  TRY_AGAIN: 'Try again',
  ERROR_GENERAL: 'Oops! There is something wrong',
  ACTIVE: 'Active',
  YES: 'Yes',
  NO: 'No',
  CHANGE_WEEK_CONFIRMATION: 'Are you sure to change the active week?',
  INACTIVE_SUBSCRIPTION_WARNING: 'You currently have no active subscription',
  TRIAL_SUBSCRIPTION_MESSAGE: ' Welcome to your free 14 days trial of Vocalise.',
  PLEASE_PURCHASE_A_SUBSCRIPTION: 'Please purchase a subscription',
  TO_CONTINUE: 'to continue',
  PURCHASE_SUBSCRIPTION: 'Purchase subscription',
  TRY_OUT_TITLE: 'Try our selection of free exercises',
  TRY_OUT_DESCRIPTION: 'You are welcome to try these free exercises before you buy',
  PREVIEW_SESSION: 'Preview Session',
  BROWSER_DOES_NOT_SUPPORT_AUDIO: 'Your browser does not support the audio element',
  HI: 'Hi',
  WELCOME_TO: 'Welcome to',
  WELCOME_INFO_DESC_1: "We are happy you're here!",
  WELCOME_INFO_DESC_2:
    "You have now access to the 3 month course 'free and healthy singing'. It includes weekly programs with 3x Singing practice, 3x Physical training for singers and a Morning Routine that warms up your body and voice.",
  WELCOME_INFO_DESC_3:
    'Scrolling down on the Dashboard you can find loose exercises that are always available when you need them. This section is called Featured sessions. Examples are a quick warm-up and mind-set for studying.',
  WELCOME_INFO_DESC_4: 'Enjoy your singing practice!',
  PURCHASE_VOCALISE: 'Start free trial 14 days',
  ALREADY_MEMBER: 'Already a member?',
  SIGN_IN_HERE: 'Sign in here',
  CREATE_ACCOUNT: 'Create account',
  CREATE_YOUR_ACCOUNT: 'Create your account',
  CREATE_ACCOUNT_DESCRIPTION:
    'For using Vocalise and saving your progress we need to store some information about you. Please create a profile and accept our privacy policy.',
  I_ACCEPT: 'I accept the',
  PROCEED_TO_CHECKOUT: 'Proceed to checkout',
  START: 'Start',
  HAVING_QUESTION: 'Having questions?',
  WHAT_IS_YOUR_NAME: 'What is your name?',
  CREATE_PROFILE_DESCRIPTION:
    'At Vocalise we would like you to feel at home and welcome. Please give us your first name so we can greet you properly.',
  ADD_TO_HOME_SCREEN: 'Add to home screen',
  INSTALL_MESSAGE: 'Install this webapp on your device',
  TAP: 'Tap',
  THEN_ADD_TO_HOME_SCREEN: 'then tap Add to Home Screen',
  DONT_ASK_AGAIN: "Don't ask again",
  LIBRARY_TITLE: 'Practice Library',
  FAVORITES: 'Favorites',
  FEATURED_PRACTICE: 'Featured Practice',
  EXERCISES: 'Exercises',
  PAGE_NOT_FOUND: 'Page not found',
  PAGE_NOT_FOUND_DESCRIPTION: 'Please contact us at info@vocalise.app for help',
  GO_BACK: 'Go Back',
  SUCCESSFULLY_ADDED_TO_FEATURED_PRACTICE: 'Successfully added to featured practice',
  SUCCESSFULLY_REMOVED_FROM_FEATURED_PRACTICE: 'Successfully removed from featured practice',
  BACK_TO_TRY_OUT: 'Back To Try Out',
  BACK_TO_DASHBOARD: 'Back to dashboard',
  VOUCHER_TITLE: 'Enter your voucher code below',
  VOUCHER_CODE: 'Voucher Code',
  REDEEM_CODE: 'Redeem code',
  VOUCHER_HERE: 'Voucher? Click here.',
  VOUCHER_USED:
    'Unfortunately this voucher code is already used. If you believe this is an error, find our contact information below',
  OKAY: 'Okay',
  NO_DATA_AVAILABLE: 'No Data Available...',
  ADD_YOUR_FEATURED: 'Add your featured',
  FROM_EXERCISES: 'exercises from the library',
  VOUCHER_NOT_FOUND:
    "Unfortunately this voucher code isn't valid. If you believe this is an error, find our contact information below",
  NEXT_PAGE: 'Next Page',
  INTRO: 'Intro',
  WRITE_YOUR_FIRST_NAME: 'Write your first name here',
  DAILY_EXERCISES: 'Daily Exercises',
  WEEKLY_EXERCISE: 'Weekly Exercises',
  SIGN_IN_AS: 'Signed in as',
  MANAGE_SUBSCRIPTION: 'Manage subscription',
  COMING_SOON: 'Coming soon',
  PAYMENT_CANCEL: 'Payment Cancel',
  PAYMENT_SUCCESS: 'Payment Success',
  VOUCHER: 'Voucher',
  CHOOSE_THE_WEEK: 'Choose the week',
  STEPS: 'Steps',
  WELL_DONE_FINISHED: 'Well done {value} ! All steps finished!',
  DISMIS: 'Dismis',
  SHOW_TIP: 'Show tip',
  BACK_TO_LIBRARY: 'Back to library',
}
