import { withAuthenticationRequired } from '@auth0/auth0-react'
import * as React from 'react'
import { Route, RouteProps } from 'react-router'
import { OnboardingRoute } from 'config/routes'
import useDocumentTitle from 'utilities/use-document-title'

interface IProps extends RouteProps {
  component: React.ComponentType
  title: string
}

const PrivateRoute: React.FC<IProps> = ({ component, title, children, ...rest }) => {
  useDocumentTitle(title)

  return (
    <Route
      component={withAuthenticationRequired(component, {
        returnTo: OnboardingRoute.generatePath(),
      })}
      {...rest}
    />
  )
}

export default PrivateRoute
