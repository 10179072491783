import { createClient } from 'contentful'
import { getLocale } from 'localization/get-locale'
import {
  ISubject,
  CONTENT_TYPE,
  IProgramFields,
  IWeekFields,
  ISession,
  ILibraryCategory,
  IDailyTip,
} from 'types/contentful'

const ContentfulClient = createClient({
  space: window.global.REACT_APP_CONTENTFUL_SPACE!,
  accessToken: window.global.REACT_APP_CONTENTFUL_TOKEN!,
  environment: window.global.REACT_APP_CONTENTFUL_ENVIRONMENT!,
})

export async function getProgram(programId: string) {
  const locale = await getLocale()
  return ContentfulClient.getEntries<IProgramFields>({
    content_type: 'program' as CONTENT_TYPE,
    'sys.id': programId,
    locale: locale,
  }).then(function (entries) {
    return entries.items.map((entry) => entry.fields)
  })
}

export async function getWeek(weekNumber: number) {
  const locale = await getLocale()
  const entries = await ContentfulClient.getEntries<IWeekFields>({
    content_type: 'week' as CONTENT_TYPE,
    'fields.week': weekNumber,
    locale: locale,
  })
  return entries.items.map((entry) => entry.fields)
}

export async function getAllWeek() {
  const locale = await getLocale()
  const entries = await ContentfulClient.getEntries<IWeekFields>({
    content_type: 'week' as CONTENT_TYPE,
    locale: locale,
  })
  return entries.items.map((entry) => entry.fields)
}

export async function getSubjects() {
  const locale = await getLocale()
  const entries = await ContentfulClient.getEntries<ISubject>({
    content_type: 'subject' as CONTENT_TYPE,
    locale: locale,
  })
  return entries.items.map((item) => {
    return ({ fields: item.fields, sys: item.sys } as unknown) as ISubject
  })
}

export async function getSessions(sessionId: string) {
  const locale = await getLocale()
  const entries = await ContentfulClient.getEntries<ISession>({
    content_type: 'session' as CONTENT_TYPE,
    'sys.id': sessionId,
    locale: locale,
  })
  return entries.items.map((item) => {
    return ({ fields: item.fields, sys: item.sys } as unknown) as ISession
  })
}

export async function getFeaturedSessions() {
  const locale = await getLocale()
  const entries = await ContentfulClient.getEntries<ISession>({
    content_type: 'session' as CONTENT_TYPE,
    'fields.featured': true,
    locale: locale,
  })
  return entries.items.map((item) => {
    return ({ fields: item.fields, sys: item.sys } as unknown) as ISession
  })
}

export async function getFreeSessions() {
  const locale = await getLocale()
  const entries = await ContentfulClient.getEntries<ISession>({
    content_type: 'session' as CONTENT_TYPE,
    'fields.availableFree': true,
    locale: locale,
  })
  return entries.items.map((item) => {
    return ({ fields: item.fields, sys: item.sys } as unknown) as ISession
  })
}

export async function getLibraryCategories() {
  const locale = await getLocale()
  const entries = await ContentfulClient.getEntries<ILibraryCategory>({
    content_type: 'libraryCategory' as CONTENT_TYPE,
    locale: locale,
  })
  return entries.items.map((item) => {
    return ({ fields: item.fields, sys: item.sys } as unknown) as ILibraryCategory
  })
}

export async function getSessionsIn(sessionIdIn: string) {
  const locale = await getLocale()
  const entries = await ContentfulClient.getEntries<ISession>({
    content_type: 'session' as CONTENT_TYPE,
    'sys.id[in]': sessionIdIn,
    locale: locale,
  })
  return entries.items.map((item) => {
    return ({ fields: item.fields, sys: item.sys } as unknown) as ISession
  })
}

export async function getDailyTip(day: number) {
  const locale = await getLocale()
  const entries = await ContentfulClient.getEntries<IDailyTip>({
    content_type: 'dailyTip' as CONTENT_TYPE,
    'fields.day': day,
    locale: locale,
  })
  return entries.items.map((item) => {
    return ({ fields: item.fields, sys: item.sys } as unknown) as IDailyTip
  })
}

// UNUSED

export type ISubjectsAndWeeks = {
  subjects: ISubject[]
  IncludesWeeks: any
}

export async function getSubjectsAndWeek(): Promise<ISubjectsAndWeeks> {
  const locale = await getLocale()
  const entries = await ContentfulClient.getEntries<ISubject>({
    content_type: 'subject' as CONTENT_TYPE,
    locale: locale,
  })

  return {
    subjects: entries.items.map((item) => {
      return ({ fields: item.fields, sys: item.sys } as unknown) as ISubject
    }),
    IncludesWeeks: entries.includes,
  }
}

export { ContentfulClient }
