export const Colors = {
  FuchsiaBlue: '#7e57c2',
  Cascade: '#87ab9b',
  Contessa: '#CA7B7B',
  LimedSpruce: '#38424B',
  BaliHai: '#8793AB',
  WhiteSmoke: '#F3F3F3',
  LightSilver: '#D8D8D8',
  White: '#ffffff',
  PinkPurpleGradient: 'linear-gradient(112.53deg,#8041e6 35.34%,#4f41e6 84.13%)',
  Black: '#000000d1',
  Apricot: '#EB8983',
  Indigo: '#597AC2',
  Silver: '#CACACA',
  CloudBurst: '#202B45',
  Concrete: '#F2F2F2',
  HintOfRed: '#F7F3F3',
  Gray: '#8E8E8E',
  Approx: '#8754AE',
  Chalice: '#A8A7A7',
  MonaLisa: '#FFA98D',
  DustyGray: '#A59A9A',
  Amethyst: '#A063CF',
  FrenchLilac: '#D9C1EB',
  Salago: `#E0E6FB`,
  RoyalBlue: `#436BF0`,
  MineShaft: '#393939',
  SwissCoffe: '#E1D6D6',
  Galery: '#EBEBEB',
  Scorpion: '#5F5F5F',
  CornflowerBlue: '#6ca5f0',
}
