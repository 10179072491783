/* tslint:disable */
/* eslint-disable */
/**
 * Vocalise.API.Web
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EntitlementDTO,
    EntitlementDTOFromJSON,
    EntitlementDTOFromJSONTyped,
    EntitlementDTOToJSON,
    UserState,
    UserStateFromJSON,
    UserStateFromJSONTyped,
    UserStateToJSON,
} from './';

/**
 * 
 * @export
 * @interface UserDTO
 */
export interface UserDTO {
    /**
     * 
     * @type {number}
     * @memberof UserDTO
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof UserDTO
     */
    identityId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserDTO
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserDTO
     */
    firstName?: string | null;
    /**
     * 
     * @type {UserState}
     * @memberof UserDTO
     */
    state?: UserState;
    /**
     * 
     * @type {number}
     * @memberof UserDTO
     */
    activeWeek?: number;
    /**
     * 
     * @type {string}
     * @memberof UserDTO
     */
    stripeId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserDTO
     */
    revenueCatId?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof UserDTO
     */
    created?: Date;
    /**
     * 
     * @type {EntitlementDTO}
     * @memberof UserDTO
     */
    entitlement?: EntitlementDTO;
}

export function UserDTOFromJSON(json: any): UserDTO {
    return UserDTOFromJSONTyped(json, false);
}

export function UserDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'identityId': !exists(json, 'identityId') ? undefined : json['identityId'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'state': !exists(json, 'state') ? undefined : UserStateFromJSON(json['state']),
        'activeWeek': !exists(json, 'activeWeek') ? undefined : json['activeWeek'],
        'stripeId': !exists(json, 'stripeId') ? undefined : json['stripeId'],
        'revenueCatId': !exists(json, 'revenueCatId') ? undefined : json['revenueCatId'],
        'created': !exists(json, 'created') ? undefined : (new Date(json['created'])),
        'entitlement': !exists(json, 'entitlement') ? undefined : EntitlementDTOFromJSON(json['entitlement']),
    };
}

export function UserDTOToJSON(value?: UserDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'identityId': value.identityId,
        'email': value.email,
        'firstName': value.firstName,
        'state': UserStateToJSON(value.state),
        'activeWeek': value.activeWeek,
        'stripeId': value.stripeId,
        'revenueCatId': value.revenueCatId,
        'created': value.created === undefined ? undefined : (value.created.toISOString()),
        'entitlement': EntitlementDTOToJSON(value.entitlement),
    };
}


