/**
 * This file contains all american english translations (messages) used in the web app.
 * Must be a flat key-value object where keys are sorted in alphanumerical order.
 * We are using the ICU message syntax: https://formatjs.io/guides/message-syntax/
 * Format keys using upper snake case and name them by a hierarchy representing location inside the app
 * Example: 'BASIC_BUTTON_ADD_TEXT'
 */

export default {
  HELLO: 'Hej',
  SERVICE_WORKER_PROVIDER_RELOAD_BUTTON: 'Ladda om',
  SERVICE_WORKER_PROVIDER_NEW_VERSION: 'Ny version tillgänglig!',
  TEST_USE_FORMAT_MESSAGE_TEXT: 'Öka',
  TEST_USE_FORMAT_MESSAGE_TEXT_AND_VALUE: 'Värde {value}',
  DASHBOARD_PAGE_VIEW_GREETING_MORNING: 'God morgon',
  DASHBOARD_PAGE_VIEW_GREETING_DAY: 'God dag',
  DASHBOARD_PAGE_VIEW_GREETING_EVENING: 'God kväll',
  ON_BOARDING_TITLE_1: 'Vi är så glada att du är här!',
  ON_BOARDING_DESCRIPTION_1:
    'Börja träna sång hemma och kombinera klassisk röstcoaching med fysisk träning för sångare.',
  ON_BOARDING_TITLE_2: 'Veckovis övning',
  ON_BOARDING_DESCRIPTION_2:
    'Ditt veckoprogram består av medföljande sångövningar och träning för att förbättra din andning, fokus och hållning för att stödja en fri och hälsosam sångröst.',
  ON_BOARDING_TITLE_3: 'Lägg till på din startskärm',
  ON_BOARDING_DESCRIPTION_3:
    'Glöm inte att lägga till den här appen på startskärmen för din bästa upplevelse.',
  LOGIN: 'Logga in',
  LOGOUT: 'Logga ut',
  SETTINGS: 'Inställningar',
  RECOMENDED_PRACTICE: 'Rekommenderade övningar',
  LANGUAGE: 'Språk',
  LANGUAGE_ERROR: 'Kunde inte hämta språk',
  CONTINUE: 'Fortsätt',
  CHECKOUT: 'Kassa',
  CREATE_PROFILE: 'Skapa profil',
  EMAIL: 'E-post',
  INVALID_EMAIL_ADDRESS: 'Ogiltig e-postadress',
  FIRST_NAME: 'Förnamn',
  BY_CLICKING_CONTINUE_YOU_AGREE_TO_VOCALISE: 'Genom att klicka på Fortsätt godkänner du Vocalises',
  TERM_OF_SERVICE: 'Användarvillkor',
  PRIVACY_POLICY: 'Integritetspolicy',
  AND: 'Och',
  COUNTRY: 'Land',
  OTHER: 'Övrig',
  PRICE: 'Pris',
  CONTACT_VOCALISE: 'Kontakta {value} för support',
  PAY: 'Betala',
  YOUR_WEEKLY_TRAINING: 'Din veckoträning',
  WEEK: 'Vecka',
  TRY_FREE_EXCERCISES: 'Prova våra gratis övningar',
  WHERE_ARE_YOU: 'Var är du?',
  PAYMENT_TITLE: 'Prova Vocalise för gratis provperiod i 14 dagar',
  PAYMENT_DESCRIPTION:
    'Förbättra din sång på ett hälsosamt sätt. Därefter kan du när som helst avsluta din prenumeration.',
  PAYMENT_PLAN_TITLE: 'Välj betalningsplan',
  PAYMENT_PROMOTION: '* Använd kampanjkoden EARLYBIRD i kassan för 30% rabatt på earlybird',
  START_NOW: 'Börja nu',
  THANK_YOU: 'Tack',
  PAYMENT_SUCCESS_DESCRIPTION: 'Tack för att du köpte Vocalise. Njut av din sångövning',
  USE: 'Använda sig av',
  PAYMENT_CANCEL_TITLE: 'Har du problem med betalningen?',
  PAYMENT_CANCEL_DESCRIPTION: 'Kontakta oss på info@vocalise.app med feedback eller frågor',
  TRY_AGAIN: 'Försök igen',
  ERROR_GENERAL: 'hoppsan! Någonting är fel',
  ACTIVE: 'Aktiv',
  YES: 'Ja',
  NO: 'Nej',
  CHANGE_WEEK_CONFIRMATION: 'Är du säker på att ändra den aktiva veckan?',
  INACTIVE_SUBSCRIPTION_WARNING: 'Du har för närvarande ingen aktiv prenumeration',
  TRIAL_SUBSCRIPTION_MESSAGE: 'Välkommen till din kostnadsfria 14 dagars provperiod på Vocalise.',
  PLEASE_PURCHASE_A_SUBSCRIPTION: 'Köp en prenumeration',
  TO_CONTINUE: 'att fortsätta',
  PURCHASE_SUBSCRIPTION: 'Köp prenumeration',
  TRY_OUT_TITLE: 'Prova vårt urval av gratis övningar',
  TRY_OUT_DESCRIPTION: 'Du är välkommen att prova dessa gratisövningar innan du köper',
  PREVIEW_SESSION: 'Förhandsgranska session',
  BROWSER_DOES_NOT_SUPPORT_AUDIO: 'Din webbläsare stöder inte ljudelementet',
  HI: 'Hej',
  WELCOME_TO: 'Välkommen till',
  WELCOME_INFO_DESC_1: 'Vi är glada att du är här!',
  WELCOME_INFO_DESC_2:
    'Du har nu tillgång till 3 månaders kursen "gratis och hälsosam sång". Den inkluderar veckoprogram med 3x sångövning, 3x fysisk träning för sångare och en morgonrutin som värmer upp din kropp och röst.',
  WELCOME_INFO_DESC_3:
    'Bläddra ner på instrumentpanelen kan du hitta lösa övningar som alltid är tillgängliga när du behöver dem. Detta avsnitt kallas utvalda sessioner. Exempel är en snabb uppvärmning och tankesätt för studier.',
  WELCOME_INFO_DESC_4: 'Njut av din sångövning!',
  PURCHASE_VOCALISE: 'Starta gratis provperiod 14 dagar',
  ALREADY_MEMBER: 'Redan medlem?',
  SIGN_IN_HERE: 'Logga in här',
  CREATE_ACCOUNT: 'Skapa konto',
  CREATE_YOUR_ACCOUNT: 'Skapa ditt konto',
  CREATE_ACCOUNT_DESCRIPTION:
    'För att använda Vocalise och spara dina framsteg måste vi lagra lite information om dig. Skapa en profil och godkänn vår integritetspolicy.',
  I_ACCEPT: 'jag accepterar',
  PROCEED_TO_CHECKOUT: 'Fortsätt till utcheckningen',
  START: 'Start',
  HAVING_QUESTION: 'Har frågor?',
  WHAT_IS_YOUR_NAME: 'Vad heter du?',
  CREATE_PROFILE_DESCRIPTION:
    'På Vocalise vill vi att du ska känna dig som hemma och välkomna. Vänligen ge oss ditt förnamn så att vi kan hälsa på dig ordentligt.',
  ADD_TO_HOME_SCREEN: 'Lägg till på hemskärmen',
  INSTALL_MESSAGE: 'Installera denna webbapp på din enhet',
  TAP: 'Knacka',
  THEN_ADD_TO_HOME_SCREEN: 'tryck sedan på Lägg till på startskärmen',
  DONT_ASK_AGAIN: 'Fråga inte igen',
  LIBRARY_TITLE: 'Övningsbibliotek',
  FAVORITES: 'Favoriter',
  FEATURED_PRACTICE: 'Utvalda övningar',
  EXERCISES: 'Övningar',
  PAGE_NOT_FOUND: 'sidan hittas inte',
  PAGE_NOT_FOUND_DESCRIPTION: 'Kontakta oss på info@vocalise.app för hjälp',
  GO_BACK: 'Gå tillbaka',
  SUCCESSFULLY_ADDED_TO_FEATURED_PRACTICE: 'Tillagd till utvald praxis',
  SUCCESSFULLY_REMOVED_FROM_FEATURED_PRACTICE: 'Har tagits bort från utvalda praxis',
  BACK_TO_TRY_OUT: 'Tillbaka för att prova',
  BACK_TO_DASHBOARD: 'Tillbaka till instrumentpanelen',
  VOUCHER_TITLE: 'Ange din kupongkod nedan',
  VOUCHER_CODE: 'Rabattkod',
  REDEEM_CODE: 'Redeem code',
  VOUCHER_HERE: 'Kupong? Klicka här.',
  VOUCHER_USED:
    'Tyvärr används denna kupongkod redan. Om du tror att detta är ett fel kan du hitta vår kontaktinformation nedan',
  OKAY: 'Okej',
  NO_DATA_AVAILABLE: 'Ingen data tillgänglig...',
  ADD_YOUR_FEATURED: 'Lägg till din utvalda',
  FROM_EXERCISES: 'övningar från biblioteket',
  VOUCHER_NOT_FOUND:
    'Tyvärr är denna kupongkod inte giltig. Om du tror att detta är ett fel kan du hitta vår kontaktinformation nedan',
  NEXT_PAGE: 'Nästa sida',
  INTRO: 'Introduktion',
  WRITE_YOUR_FIRST_NAME: 'Skriv ditt förnamn här',
  DAILY_EXERCISES: 'Dagliga övningar',
  WEEKLY_EXERCISE: 'Veckovisa övningar',
  SIGN_IN_AS: 'Inloggad som',
  MANAGE_SUBSCRIPTION: 'Hantera prenumeration',
  COMING_SOON: 'Kommer snart',
  PAYMENT_CANCEL: 'Avbokning av betalning',
  PAYMENT_SUCCESS: 'Betalningsframgång',
  VOUCHER: 'Kupong',
  CHOOSE_THE_WEEK: 'Välj veckan',
  STEPS: 'Steg',
  WELL_DONE_FINISHED: 'Bra gjort {value}! Alla steg färdiga!',
  DISMIS: 'Avfärda',
  SHOW_TIP: 'Visa tip',
  BACK_TO_LIBRARY: 'Tillbaka till biblioteket',
}
