import enUS from './messages.en-us'
import svSE from './messages.sv-se'

export { svSE, enUS }

export const getMessages = async (locale: string) => {
  const fileName = `./messages.${locale.toLowerCase()}`
  try {
    const data = await import(`${fileName}`)
    return data.default
  } catch (err) {
    return enUS
  }
}
