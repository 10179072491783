import DateFnsUtils from '@date-io/date-fns'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import * as React from 'react'
import { IntlProvider } from 'react-intl'
import { invalidateContentfulQueries } from 'queries/contentful-query'
import { LocaleContextType } from 'types/context'
import { getLocale, getLocaleFns, setLocale } from './get-locale'
import { getMessages, enUS } from './messages'

const LocaleContext = React.createContext<LocaleContextType | undefined>(undefined)

export const useLocaleContext = () => {
  const context = React.useContext(LocaleContext)
  if (!context) throw new Error('useLocaleContext must be used within a LocalizationProvider')
  return context
}

const LocalizationProvider: React.FunctionComponent = ({ children }) => {
  const [currentLocale, setCurentLocale] = React.useState('en-US')
  const [messages, setMessages] = React.useState(enUS)

  React.useEffect(() => {
    const getLanguage = async () => {
      const locale = await getLocale()
      const msg = await getMessages(locale)
      setCurentLocale(locale)
      setMessages(msg)
    }
    getLanguage()
  }, [])

  React.useEffect(() => {
    const changeLanguage = async () => {
      setLocale(currentLocale)
      const msg = await getMessages(currentLocale)
      setMessages(msg)
      invalidateContentfulQueries()
    }
    changeLanguage()
  }, [currentLocale])
  return (
    <LocaleContext.Provider value={{ locale: currentLocale, setLocale: setCurentLocale }}>
      <MuiPickersUtilsProvider
        key={currentLocale}
        utils={DateFnsUtils}
        locale={getLocaleFns(currentLocale)}>
        <IntlProvider
          key={currentLocale} // Add the key prop to make sure the app is re-rendered when changing locale
          locale={currentLocale}
          messages={messages}>
          {children}
        </IntlProvider>
      </MuiPickersUtilsProvider>
    </LocaleContext.Provider>
  )
}

export default LocalizationProvider
