/* tslint:disable */
/* eslint-disable */
/**
 * Vocalise.API.Web
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FavoriteItem
 */
export interface FavoriteItem {
    /**
     * 
     * @type {number}
     * @memberof FavoriteItem
     */
    userId?: number;
    /**
     * 
     * @type {string}
     * @memberof FavoriteItem
     */
    contentfulType?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FavoriteItem
     */
    contentfulId?: string | null;
}

export function FavoriteItemFromJSON(json: any): FavoriteItem {
    return FavoriteItemFromJSONTyped(json, false);
}

export function FavoriteItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): FavoriteItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'contentfulType': !exists(json, 'contentfulType') ? undefined : json['contentfulType'],
        'contentfulId': !exists(json, 'contentfulId') ? undefined : json['contentfulId'],
    };
}

export function FavoriteItemToJSON(value?: FavoriteItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'userId': value.userId,
        'contentfulType': value.contentfulType,
        'contentfulId': value.contentfulId,
    };
}


