import * as React from 'react'
import { generateRoute } from './utilities/generate-route'

/* When importing pages lazily, exported pages needs to be default export */
export const DashboardRoute = generateRoute({
  pattern: '/dashboard',
  exact: true,
  component: React.lazy(() => import('pages/dashboard-page')),
})

export const SelfAssessmentRoute = generateRoute({
  pattern: '/assessment',
  exact: false,
  component: React.lazy(() => import('pages/range-check-page/range-check-page-view')),
})

export const PreviewSessionRoute = generateRoute<{
  programId: string
  subjectId: string
  sessionId: string
  weekId: number
  sessionType: 'daily' | 'weekly' | 'instant'
}>({
  pattern: '/preview-session/:programId/:subjectId/:weekId/:sessionId/:sessionType',
  exact: true,
  component: React.lazy(() => import('pages/preview-session-page')),
})

export const StepRoute = generateRoute<{
  programId: string
  subjectId: string
  sessionId: string
  weekId: number
  baseLocation: string
  sessionType: 'daily' | 'weekly' | 'instant'
}>({
  pattern: `/step/:programId/:subjectId/:weekId/:sessionId/:baseLocation/:sessionType`,
  exact: true,
  component: React.lazy(() => import('pages/step-page')),
})

export const WelcomeRoute = generateRoute({
  pattern: '/welcome',
  exact: true,
  component: React.lazy(() => import('pages/welcome-page')),
})

export const PaymentRoute = generateRoute({
  pattern: '/checkout',
  exact: true,
  component: React.lazy(() => import('pages/payment-page')),
})

export const OnboardingRoute = generateRoute({
  pattern: '/',
  exact: true,
  component: React.lazy(() => import('pages/onboarding-page')),
})

export const PartialFormExampleRoute = generateRoute({
  pattern: '/partial-form-example',
  exact: false,
  component: React.lazy(() => import('pages/partial-form-example-page')),
})

export const NotFoundRoute = generateRoute({
  pattern: '*',
  exact: false,
  component: React.lazy(() => import('pages/not-found-page')),
})

export const SettingRoute = generateRoute({
  pattern: '/setting-page',
  exact: false,
  component: React.lazy(() => import('pages/setting-page')),
})

export const TryOutRoute = generateRoute({
  pattern: '/try-out-page',
  exact: false,
  component: React.lazy(() => import('pages/try-out-page')),
})

export const PaymentSuccessRoute = generateRoute({
  pattern: '/payment-success',
  exact: false,
  component: React.lazy(() => import('pages/payment-success-page')),
})

export const PaymentCancelRoute = generateRoute({
  pattern: '/payment-cancel',
  exact: false,
  component: React.lazy(() => import('pages/payment-cancel-page')),
})

export const CreateAccountRoute = generateRoute({
  pattern: '/create-account',
  exact: false,
  component: React.lazy(() => import('pages/create-account-page')),
})

export const TryOutStepRoute = generateRoute<{
  programId: string
  subjectId: string
  sessionId: string
  weekId: number
}>({
  pattern: '/try-out-step/:programId/:subjectId/:weekId/:sessionId',
  exact: true,
  component: React.lazy(() => import('pages/try-out-session-page')),
})

export const LibraryRoute = generateRoute({
  pattern: '/library',
  exact: false,
  component: React.lazy(() => import('pages/library-page')),
})

export const VoucherRoute = generateRoute({
  pattern: '/voucher',
  exact: false,
  component: React.lazy(() => import('pages/voucher-page')),
})

export const RedirectRoute = generateRoute({
  pattern: '/redirect',
  exact: true,
  component: React.lazy(() => import('pages/redirect-page')),
})
