/* tslint:disable */
/* eslint-disable */
/**
 * Vocalise.API.Web
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PriceCurrencyDTO
 */
export interface PriceCurrencyDTO {
    /**
     * 
     * @type {string}
     * @memberof PriceCurrencyDTO
     */
    countryName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PriceCurrencyDTO
     */
    isoCountryCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PriceCurrencyDTO
     */
    countryCurrency?: string | null;
    /**
     * 
     * @type {number}
     * @memberof PriceCurrencyDTO
     */
    price?: number;
}

export function PriceCurrencyDTOFromJSON(json: any): PriceCurrencyDTO {
    return PriceCurrencyDTOFromJSONTyped(json, false);
}

export function PriceCurrencyDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): PriceCurrencyDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'countryName': !exists(json, 'countryName') ? undefined : json['countryName'],
        'isoCountryCode': !exists(json, 'isoCountryCode') ? undefined : json['isoCountryCode'],
        'countryCurrency': !exists(json, 'countryCurrency') ? undefined : json['countryCurrency'],
        'price': !exists(json, 'price') ? undefined : json['price'],
    };
}

export function PriceCurrencyDTOToJSON(value?: PriceCurrencyDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'countryName': value.countryName,
        'isoCountryCode': value.isoCountryCode,
        'countryCurrency': value.countryCurrency,
        'price': value.price,
    };
}


